import React from "react";

import styles from "./HomeNetworks.module.css";

export const HomeNetworks = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.network}>
      <svg
        width="40"
        height="2"
        viewBox="0 0 40 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.stroke}
      >
        <line
          y1="1"
          x2="40"
          y2="1"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="2 6"
        />
      </svg>
      <div className={styles.logos}>
        <div className={styles.visa}>
          <svg
            width="80"
            height="40"
            viewBox="0 0 80 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.6155 7.44762L19.4036 31.9416H12.7412L7.71587 12.3943C7.41077 11.1903 7.14549 10.7492 6.21762 10.242C4.70273 9.41574 2.20105 8.64058 0 8.15948L0.149499 7.44762H10.874C12.2411 7.44762 13.47 8.36245 13.7803 9.94504L16.4344 24.1185L22.9936 7.44721H29.6155V7.44762ZM55.7202 23.9445C55.747 17.4798 46.8282 17.1237 46.8895 14.2358C46.9086 13.3569 47.741 12.4225 49.5631 12.1839C50.4661 12.0651 52.9544 11.9744 55.7766 13.2801L56.8836 8.08596C55.3671 7.53257 53.4159 7 50.9882 7C44.7584 7 40.3741 10.3294 40.3372 15.0967C40.2969 18.6229 43.4665 20.5907 45.8544 21.7624C48.311 22.9623 49.1353 23.7334 49.1259 24.8067C49.1085 26.4497 47.1666 27.175 45.3519 27.2036C42.184 27.2526 40.3457 26.3419 38.8804 25.657L37.738 31.0231C39.2106 31.7023 41.9288 32.2949 44.747 32.3247C51.3684 32.3247 55.6998 29.0366 55.7202 23.9445ZM72.1708 31.9416H78L72.9117 7.44762H67.5314C66.3216 7.44762 65.3011 8.1558 64.8494 9.24462L55.3915 31.9416H62.0097L63.3235 28.2831H71.4099L72.1708 31.9416ZM65.1382 23.2629L68.4556 14.0663L70.365 23.2629H65.1382ZM38.6208 7.44762L33.409 31.9416H27.1065L32.3203 7.44762H38.6208V7.44762Z"
              fill="black"
            />
          </svg>
        </div>
        <div className={styles.mc}>
          <svg
            width="80"
            height="40"
            viewBox="0 0 80 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_307_2)">
              <path
                d="M67.17 30.455V30.575ZM38.53 34.63C38.67 34.75 38.82 34.87 38.965 34.99C36.11 36.89 32.685 38 29 38C19.06 38 11 29.94 11 20C11 10.06 19.06 2 29 2C32.685 2 36.11 3.11 38.965 5.01C38.82 5.13 38.67 5.245 38.53 5.37C34.29 9.06 31.855 14.395 31.855 20C31.855 25.605 34.285 30.94 38.53 34.63V34.63ZM51.25 2C47.565 2 44.14 3.11 41.285 5.01C41.43 5.13 41.58 5.245 41.72 5.37C45.96 9.06 48.395 14.39 48.395 20C48.395 25.61 45.965 30.94 41.725 34.63C41.585 34.75 41.435 34.87 41.29 34.99C44.145 36.89 47.57 38 51.255 38C61.195 38 69.255 29.94 69.255 20C69.255 10.06 61.19 2 51.25 2ZM40.125 5.855C39.895 6.035 39.665 6.225 39.445 6.42C35.65 9.72 33.25 14.58 33.25 20C33.25 25.42 35.65 30.28 39.445 33.58C39.665 33.775 39.895 33.96 40.125 34.145C40.355 33.96 40.585 33.775 40.805 33.58C44.6 30.28 47 25.42 47 20C47 14.58 44.6 9.72 40.805 6.42C40.585 6.225 40.355 6.04 40.125 5.855"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_307_2">
                <rect
                  width="58.25"
                  height="36"
                  fill="white"
                  transform="translate(11 2)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <div className={styles.data}>
      <div className={styles.dataScroll}>
        <div>Merchant Name</div>
        <div>Merchant Category</div>
        <div>Merchant Code</div>
        <div>Merchant Address</div>
        <div>Merchant Description</div>
        <div>Point of Service Category</div>
        <div>PIN Entry Mode</div>
        <div>PAN Entry Mode</div>
        <div>Card Holder Present</div>
        <div>Card Present</div>
        <div>Terminal Attendance</div>
        <div>Processing Type</div>
        <div>Network Response Code</div>
        <div>CVV Response Code</div>
      </div>
    </div>
    <div className={styles.gradientTop}></div>
    <div className={styles.gradientBottom}></div>
    <div className={styles.gradientRight}></div>
  </div>
);
