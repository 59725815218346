"use client";

import React, { useCallback, useState } from "react";
import Link from "next/link";

import { MARKETING_URL } from "../urlHelper";
import { MobileNavPopover } from "../MobileNavPopover";

import { Hamburger } from "./nav/Hamburger";

import { NavBarLayout } from "./nav/NavBarLayout";
import { NavContainer } from "./nav/NavContainer";
import { AccountMobileNav } from "./AccountNav";

interface MarketingNavProperties {
  backgroundClass?: string;
}

const NAV_ITEMS = [
  ["platform", "Platform"],
  ["products", "Products"],
  ["solutions", "Solutions"],
  ["pricing", "Pricing"],
  ["about", "Company"],
];

export const MarketingDesktopNav = (): JSX.Element => (
  <nav className="hidden items-center lg:flex">
    {NAV_ITEMS.map(([path, linkContent]) => {
      if (path === "solutions") {
        return (
          <div className="group relative mx-3 cursor-pointer text-sm">
            <div className="leading-10">{linkContent}</div>
            <div className="rounded-highnote drop-shadow-nav invisible absolute -left-5 w-56 -translate-y-2 bg-white px-1 py-3 opacity-0 duration-100 group-hover:visible group-hover:translate-y-0 group-hover:opacity-100">
              <Link
                className="hover:bg-bone group/item flex items-center rounded-md px-4 py-1.5"
                href={`${MARKETING_URL}/solutions/fleet`}
              >
                Fleet
                <span className="inline-block">
                  <img
                    alt=""
                    className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover/item:translate-x-1 group-hover/item:opacity-100"
                    src="/img/black-arrow-icon.svg"
                  />
                </span>
              </Link>
              <Link
                className="hover:bg-bone group/item flex items-center rounded-md px-4 py-1.5"
                href={`${MARKETING_URL}/solutions/ap-automation`}
              >
                AP Automation
                <span className="inline-block">
                  <img
                    alt=""
                    className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover/item:translate-x-1 group-hover/item:opacity-100"
                    src="/img/black-arrow-icon.svg"
                  />
                </span>
              </Link>
              <Link
                className="hover:bg-bone group/item flex items-center rounded-md px-4 py-1.5"
                href={`${MARKETING_URL}/solutions/corporate-expense`}
              >
                Corporate Expense
                <span className="inline-block">
                  <img
                    alt=""
                    className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover/item:translate-x-1 group-hover/item:opacity-100"
                    src="/img/black-arrow-icon.svg"
                  />
                </span>
              </Link>
              <Link
                className="hover:bg-bone group/item flex items-center rounded-md px-4 py-1.5"
                href={`${MARKETING_URL}/solutions/construction`}
              >
                Construction
                <span className="inline-block">
                  <img
                    alt=""
                    className="-translate-x-1 opacity-0 duration-100 ease-in-out group-hover/item:translate-x-1 group-hover/item:opacity-100"
                    src="/img/black-arrow-icon.svg"
                  />
                </span>
              </Link>
            </div>
          </div>
        );
      }
      return (
        <Link
          href={`${MARKETING_URL}/${path}`}
          key={path}
          className="group relative mx-3 text-sm"
          data-testid={`marketingNav::${path}`}
        >
          {linkContent}
          <span className="absolute left-0 block h-0.5 w-0 origin-bottom-right bg-black duration-100 ease-in-out group-hover:w-full group-hover:origin-bottom-left" />
        </Link>
      );
    })}
  </nav>
);

export const MarketingMobileNav = (): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShowDropdown = useCallback(() => {
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  return (
    <nav className="border-bone grid grid-cols-1 border-b">
      {NAV_ITEMS.map(([path, linkContent]) => {
        if (path === "solutions") {
          return (
            <>
              <div
                onClick={handleShowDropdown}
                className="hover:bg-bone border-bone flex flex w-full cursor-pointer items-center justify-between border-t px-4 py-3 text-base duration-100 ease-in-out"
              >
                {linkContent}
                <img
                  alt=""
                  className="inline-block rotate-90"
                  src="/img/black-arrow-icon.svg"
                />
              </div>
              {showDropdown && (
                <div className="rounded-highnote text-base">
                  <Link
                    className="hover:bg-bone group/item flex items-center rounded-md px-4 py-3"
                    href={`${MARKETING_URL}/solutions/fleet`}
                  >
                    Fleet
                  </Link>
                  <Link
                    className="hover:bg-bone group/item flex items-center rounded-md px-4 py-3"
                    href={`${MARKETING_URL}/solutions/ap-automation`}
                  >
                    AP Automation
                  </Link>
                  <Link
                    className="hover:bg-bone group/item flex items-center rounded-md px-4 py-3"
                    href={`${MARKETING_URL}/solutions/corporate-expense`}
                  >
                    Corporate Expense
                  </Link>
                  <Link
                    className="hover:bg-bone group/item flex items-center rounded-md px-4 py-3"
                    href={`${MARKETING_URL}/solutions/construction`}
                  >
                    Construction
                  </Link>
                </div>
              )}
            </>
          );
        }
        return (
          <Link
            href={`${MARKETING_URL}/${path}`}
            key={path}
            className="hover:bg-bone border-bone flex items-center border-t px-4 py-3 text-base duration-100 ease-in-out"
            data-testid={`marketingNav::${path}`}
          >
            {linkContent}
          </Link>
        );
      })}
    </nav>
  );
};

export const MarketingNav = ({
  backgroundClass,
}: Readonly<MarketingNavProperties>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <NavContainer backgroundClass={backgroundClass}>
      <div className="relative mx-auto max-w-screen-2xl">
        <NavBarLayout>
          <MarketingDesktopNav />
        </NavBarLayout>
        <Hamburger onClick={toggleIsOpen} />
      </div>
      <MobileNavPopover isOpen={isOpen} onClick={toggleIsOpen}>
        <MarketingMobileNav />
        <AccountMobileNav />
      </MobileNavPopover>
    </NavContainer>
  );
};
